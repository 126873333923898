/* 
.image-gallery {
    margin: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1vh;
    margin-inline: auto;
    max-width: 100rem;
    padding: 1vh;
  }
  
  .image-gallery__item {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    transition: all 0.3s ease;
  }
  
  .image-gallery:has(img:hover) img:not(:hover) {
    scale: 0.8;
    opacity: 0.5;
    filter: grayscale(70%);
  }
   */







   .image-gallery {
    margin: 30px;
    display: grid;
    /* Adjusting the grid template to decrease the size of the images */
    grid-template-columns: repeat(4, 1fr); /* Change the 3 to 4 to have 4 images in a row, decreasing their size */
    gap: 1vh;
    margin-inline: auto;
    max-width: 100rem;
    padding: 1vh;
}

.image-gallery__item {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.image-gallery__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    transition: all 0.3s ease;
}

.image-gallery__item:hover .image-gallery__img {
    transform: scale(1.05);
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.image-gallery__item:hover .image-overlay {
    opacity: 1;
}

.image-gallery__item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.image-gallery__item p {
    font-size: 1rem;
    margin: 0;
}
