.blogheader__bg {
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../Image/blogbg.webp');
    padding: 100px;
  }
  @media (min-width: 820px) and (max-width: 1180px) {
    .blog__detail {
      width: 700px;
      text-align: justify;
    }
  }
  @media screen and (max-width: 580px) {
    .blogheader__bg {
      display: none;
    }
  }
  