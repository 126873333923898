.dataaddform {
background-color: #DCDCDC;

width: 100%;
margin: 50px;
padding: 50px;
width: 1000px;

    }
.dataaddform .formheading{
    font-size: 30px;
    text-align: center;
}

    .form-container {
        max-height: 500px; /* Adjust this value as needed */
        overflow-y: auto;
        padding: 15px;
        /* border: 1px solid #ccc; */
        border-radius: 5px;
      }