.aboutus__bg{

  background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../Image/Header1.webp');
    padding: 100px;
    
  }

  .location__about2{
    position: relative;
    height: 350px;
    background: linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url('../Image/Lancaster.webp');
    background-repeat: no-repeat;
    background-size: cover;

    }
    
    .core__team img{
      height: 200px;
      width: 180px;
    }



.about__testimonial img{
  width: 100px;
  border-radius: 100%;
}




@media screen and (max-width: 580px) {

  .aboutus__bg{
background: gray;
padding: 10px;
    }
 
  
.aboutus__content{
text-align: justify;
}

.core__team img{
height: 200px;
width: 150px;
}

      

.location__about2{
  height: 250px;
}
.location__detail{
  margin-left: 50px;
  margin-top: -50px;
}


.about__testimonial{
  width: 500px;
  
}

.about__testimonial p{
 line-height: 15px;
}
.about__testimonial img{
  width: 30px;
  margin-top: 10px;
}
.about__testimonial h1{
  font-size: small;
}
.testimonial__about{
  padding: 5% !important;
}
  }
  
   @media (min-width: 820px) and (max-width: 1180px) {
    .about__testimonial img{
      width: 40px;
      margin-top: 10px;
    }
   }

  @media (min-width: 581px) and (max-width: 767px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    
  }
  
  @media (min-width: 992px) and (max-width: 1280px) {
    
 }
  
  @media (min-width: 1281px) and (max-width: 1440px) {
 
 }
  
  @media (min-width: 1441px) and (max-width: 1920px) {
 
 }

 



  